import { createClient, EntryCollection } from 'contentful'
import { useEffect, useState } from 'react'
import { extractFields } from '../../utils/objects'
import { Product } from '../../common/product-helper'

export const useContentfulProduct = (productExternalName: string) => {
  const [contentfulError, setContentfulError] = useState('')
  const [contentfulEntry, setContentfulEntry] = useState<Product>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_KEY,
      environment: process.env.REACT_APP_CONTENTFUL_ENV,
    })
    const fetchData = async () => {
      try {
        const salesforceCode = productExternalName
        const res = await client.getEntries<EntryCollection<unknown>>({
          content_type: 'product',
          'fields.salesforceCode': salesforceCode,
          include: 5,
        })
        if (res.items.length > 1) {
          throw new Error(
            'Expected only one product for salesforceCode: ' + salesforceCode
          )
        }
        const contentfulProduct = extractFields(res.items[0].fields) as any
        if (contentfulProduct.salesforceCode.trim() !== salesforceCode.trim()) {
          throw new Error(
            'Product should match on salesforceCode: ' + salesforceCode
          )
        }
        setContentfulEntry(contentfulProduct)
      } catch (error) {
        setContentfulError(
          'There was an error loading the form, please try again later.'
        )
        setLoading(false)
      }
    }
    fetchData()
  }, [productExternalName])
  return {
    contentfulEntry,
    contentfulError,
    loading,
  }
}
