import 'rxjs'

import { ActionsObservable, Epic } from 'redux-observable'
import { Observable } from 'rxjs/Observable'
import { ajax } from 'rxjs/observable/dom/ajax'
import numeral from 'numeral'
import * as actions from './account-details.actions'
import { Account } from '../accounts/accounts.model'
import {
  AccountDetailsState,
  AccountDetail,
  Section,
} from './account-details.model'

const ffmAppServerUrl = process.env.REACT_APP_FFM_ONLINE_API_URL!

const INVESTMENT_EARNINGS_SECTION_NAME = 'Investment earnings'

type GetMarketEarningsProps = {
  account: Account
  accountDetails: AccountDetail
}

const getMarketEarnings = ({
  account,
  accountDetails,
}: GetMarketEarningsProps) => {
  // Apex accounts will include returnSinceInception
  if (account.returnSinceInception || account.returnSinceInception === 0) {
    return account.returnSinceInception
  }

  // Hotfix for FFKP without returnSinceInception
  if (account.productExternalRef === 'FFKP') {
    return null
  }

  // For FF accounts we calculate it manually.
  const { accountTotal: accountTotalWithPieTax, pieTaxAccrued } = account
  const { sections, accountTotal: accountDetailsTotal } = accountDetails

  const accountTotalWithoutPieTax = numeral(accountTotalWithPieTax)
    .subtract(pieTaxAccrued)
    .value()

  // API might send an "Investment Earnings" section
  const investmentEarningsIndex = sections.findIndex(
    ({ displayName }: Section) =>
      displayName === INVESTMENT_EARNINGS_SECTION_NAME
  )

  const existingInvestmentEarnings =
    investmentEarningsIndex !== -1
      ? sections.splice(investmentEarningsIndex, 1)[0]
      : null

  // The cached account and accountDetails use 2 different TEL endpoints - only 1 returns pieTaxAccrual and adjusts the accountTotal accordingly.
  // Any comparisions between the 2 therefore need to factor that in.
  return numeral(accountTotalWithoutPieTax)
    .add(existingInvestmentEarnings?.total ?? 0)
    .subtract(accountDetailsTotal)
    .value()
}

type LightStore = { getState: Function; dispatch: Function }

export const accountDetailsEpic: Epic<
  actions.AccountDetailsActions,
  AccountDetailsState
> = (
  action$: ActionsObservable<actions.AccountDetailsRequestActionType>,
  store: LightStore
) =>
  action$
    .ofType(actions.actionTypes.ACCOUNT_DETAILS_REQUEST)
    .mergeMap((action) => {
      // Retrieve the account details from the cache.
      const accountsDetails = store.getState().accountDetails
      // If there are cached account details then use them.
      if (!!accountsDetails.accounts?.length) {
        const accountDetails = accountsDetails.accounts.find(
          (acc: AccountDetail) => acc.accountID === action.payload.accountId
        )
        if (accountDetails) {
          return Observable.of(
            actions.AccountDetailsRequestSuccessAction(accountDetails)
          )
        }
      }

      return ajax
        .get(
          `${ffmAppServerUrl}/api/users/${action.payload.userId}/accounts/${action.payload.accountId}`,
          {
            Authorization:
              'Bearer ' + store.getState().authorisation.authorisationToken,
          }
        )
        .map((response) => {
          // Retrieve cached account data.
          const accounts = store.getState().accounts.accounts
          const account = accounts.find(
            (acc: Account) => acc.accountID === action.payload.accountId
          )

          const accountTransactionDetails: AccountDetail =
            response.response.accountDetails
          const {
            sections,
            contributionsTotal,
            accountTotal: sumAccountTransactions,
          } = accountTransactionDetails

          const accountTotalWithPieTax = account.accountTotal
          const marketEarnings = getMarketEarnings({
            account,
            accountDetails: accountTransactionDetails,
          })

          const transformedSections = sections.filter(
            (s) => s.displayName !== INVESTMENT_EARNINGS_SECTION_NAME
          )
          // Add a section to represent Investment Earnings for an account.
          if (marketEarnings !== null) {
            const investmentEarnings: Section = {
              displayName: INVESTMENT_EARNINGS_SECTION_NAME,
              total: marketEarnings,
              details: [
                {
                  displayName: null,
                  total: marketEarnings,
                },
              ],
            }

            transformedSections.push(investmentEarnings)
          }

          const pieTaxlabel = account.pieTaxAccrued >= 0 ? 'refund' : 'payable'

          // Add a section to represent PIE tax accrued for an account.
          const pieTaxAccrued: Section = {
            displayName: 'PIE tax accrued year to date',
            total: account.pieTaxAccrued,
            details: [
              {
                displayName: `Year to date PIE tax ${pieTaxlabel}`,
                total: account.pieTaxAccrued,
                tooltip:
                  'The total amount of PIE tax payable or refundable on your account for the year to date should you make a full withdrawal. A negative number indicates PIE tax payable, a positive number indicates PIE tax refundable.',
              },
            ],
          }
          transformedSections.push(pieTaxAccrued)

          // Build the Account Details state using cached account data and data from the account details endpoint.
          const calculatedAccountDetails: AccountDetail = {
            accountID: account.accountID,
            accountNumber: account.accountNumber,
            accountTotal: sumAccountTransactions,
            productTotal: accountTotalWithPieTax,
            productName: account.productName,
            pieTaxAccrued: account.pieTaxAccrued,
            sections: transformedSections,
            marketEarnings,
            contributionsTotal,
            profile: accountTransactionDetails.profile,
          }

          return actions.AccountDetailsRequestSuccessAction(
            calculatedAccountDetails
          )
        })
        .catch((err) =>
          Observable.of(
            actions.AccountDetailsRequestFailureAction(
              'Account details request failed'
            )
          )
        )
    })
